footer {
  background-color: #f2f2f2;
  text-align: center;
  position: relative;
  padding-bottom: 6%;
}

.footer-content {
  display: flex;
  justify-content: center;
  gap: 1.5vw;
  padding: 5vw;
}

.footer-content a {
  text-decoration: none;
  color: #0b0a09;
  font-family: 'Inter', sans-serif;
  font-size: 1.5vw;
  font-weight: 400;
  line-height: 2vw;
  letter-spacing: 0em;
  cursor: pointer;
  margin-top: 4vw;
}

.footer-content a:hover {
  color: rgb(112, 112, 112);
}

footer::before {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  height: 1px;
  background-color: black;
}

footer img {
  width: 18vw;
  height: auto;
  display: block;
  margin: 0 auto;
}

.footer-space-around-text {
  display: flex;
  justify-content: space-between;
  margin-top: 2vw;
  padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 2%;
}

.footer-space-around-text p {
  font-family: 'Inter', sans-serif;
  font-size: 1.2vw;
  font-weight: 300;
  line-height: 2vw;
  letter-spacing: 0em;
  text-align: left;
  color: black;
  margin: 0;
}

.footer-developer-text {
  font-family: 'Noah Grotesque-Trial', sans-serif;
  font-size: 1.1vw;
  font-weight: 500;
  line-height: 2vw;
  letter-spacing: 0em;
  color: #828282;
  display: inline;
}
.footer-developer-text p {
  display: inline;
  margin: 0;
}
.footer-developer-text a {
  color: #828282;
  text-decoration: none;
  border-bottom: 1px solid #828282;
}
