.header-mobile {
  position: absolute;
  width: 100%;
  top: 0;
  background: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5%;
  box-sizing: border-box;
  z-index: 1001;
}

.header-not-main-mobile {
  position: fixed;
  width: 100%;
  top: 0;
  background: #f6f4f4;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2vw 4vw;
  box-sizing: border-box;
  z-index: 1001;
}

.sticky-header-mobile {
  position: fixed;
  width: 100%;
  top: 0;
  background: #f6f4f4;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2vw 4vw;
  box-sizing: border-box;
  z-index: 1001;
  transition: background-color 0.3s ease;
}

.x-mark-mobile {
  position: relative;
  top: 1vw;
  right: 21vw;
  width: 4vw;
  height: 4vw;
  user-select: none;
}

.x-mark-black-mobile {
  cursor: pointer;
  position: relative;
  width: 6vw;
  height: 6vw;
  top: 0.1vw;
  user-select: none;
}

.main-logo-mobile {
  height: auto;
  /* padding: 3vw; */
}

.main-logo-mobile img {
  width: 25vw;
  height: auto;
}

.menu-controls-mobile {
  padding-right: 0.5vw;
  display: flex;
  align-items: center;
}

.sticky-menu-controls-mobile {
  padding-right: 0.5vw;
  display: flex;
  align-items: center;
}

.phone-number-mobile {
  text-decoration: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 4.5vw;
  border-radius: 100px;
  font-family: 'Inter', sans-serif;
  font-size: 4vw;
  font-weight: 400;
  line-height: 0vw;
  letter-spacing: 0;
  text-align: left;
  cursor: pointer;
  margin-right: 1vw;
}

.phone-icon-mobile {
  padding-right: 1vw;
  height: 4.5vw;
  width: auto;
}

.phone-number-mobile a {
  color: #000000;
  text-decoration: none;
}

.phone-number-mobile a:hover {
  text-decoration: none;
}

.menu-button-mobile {
  background-color: transparent;
  border: none;
  cursor: pointer;
  position: relative;
  width: 8vw;
  height: 8vw;
  border-radius: 50%;
  color: #f6f4f4;
  transition: background-color 0.3s ease;
}

.menu-button-mobile-clicked {
  border: 1px solid transparent;
  background-color: transparent;
}

.sticky-menu-button-mobile {
  background-color: transparent;
  border: none;
  cursor: pointer;
  position: relative;
  width: 4vw;
  height: 4vw;
  border-radius: 50%;
  transition: background-color 0.3s ease;
}

.menu-button-mobile-mark {
  user-select: none;
}

.menu-button-mobile::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 12vw;
  height: 12vw;
  border-radius: 50%;
  background-color:#0b0a09;
  backdrop-filter: blur(2px);
}

.menu-button-mobile-not {
  background-color: transparent;
  border: none;
  position: relative;
  width: 8vw;
  height: 8vw;
  border-radius: 50%;
}

.menu-icon-mobile {
  text-decoration: none;
  border: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #f6f4f4;
  width: 6vw;
  height: 6vw;
}

.menu-mobile {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f6f4f4;

  z-index: 1000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: auto;
}

.menu-mobile div {
  list-style-type: none;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  color: #000;
  padding: 2vw;
  margin-bottom: 5vw;
}

.language-switch-mobile div {
  font-family: 'Inter', sans-serif;
  font-size: 3vw;
  font-weight: 500;
  line-height: 2vw;
  border-bottom: 1px solid;
}

.language-switch-mobile {
  margin-left: 36%;
  margin-right: 36%;
}

.menu-mobile div li {
  margin-top: 5vw;
  position: relative;
  cursor: pointer;
}

.menu-mobile div li a {
  font-family: 'Inter', sans-serif;
  font-size: 4.5vw;
  font-weight: 400;
  line-height: 1.6vw;
  letter-spacing: 0em;
  text-decoration: none;
  color: #000;
}

.menu-mobile div li a:hover {
  padding-bottom: 1.5vw;
  border-bottom: 1px solid #000;
}

.additional-content-mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.additional-content-mobile a {
  padding-bottom: 4vw;
  font-family: 'Inter', sans-serif;
  font-size: 3.5vw;
  font-weight: 300;
  line-height: 1.6vw;
  text-align: left;
  margin: 3vw 2vw;
  letter-spacing: 0em;
  text-decoration: none;
  color: #828282;
  transition: color 0.3s ease;
}
.additional-content-mobile a:hover {
  color: #3c3c3c;
}
