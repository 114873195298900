.footer-mobile {
  padding-top: 2vw;
  background-color: #f2f2f2;
  text-align: center;
  position: relative;
  padding-bottom: 14%;
  height: auto;
}

.footer-content-mobile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 8vw;
  gap: 3vw;
}

.footer-content-mobile a {
  text-decoration: none;
  color: #0b0a09;
  font-family: 'Inter', sans-serif;
  font-size: 4.5vw;
  font-weight: 400;
  line-height: 8vw;
  letter-spacing: 0em;
  cursor: pointer;
}

.footer-content-mobile a:hover {
  color: rgb(112, 112, 112);
}

.footer-mobile::before {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 85%;
  height: 1px;
  background-color: black;
}

.footer-mobile img {
  width: 28vw;
  height: auto;
  display: block;
  margin: 2vw auto;
  padding-bottom: 5vw;
}

.footer-space-around-text-mobile {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  margin-top: 2vw;
  padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 2%;
}

.footer-space-around-text-mobile p {
  font-family: 'Inter', sans-serif;
  font-size: 4vw;
  font-weight: 300;
  line-height: 8vw;
  letter-spacing: 0em;
  text-align: left;
  color: black;
  margin: 0;
}

.footer-developer-text-mobile {
  font-family: 'Noah Grotesque-Trial', sans-serif;
  font-size: 3.1vw;
  font-weight: 500;
  line-height: 2vw;
  letter-spacing: 0em;
  color: #828282;
}

.footer-developer-text-mobile p {
  display: inline;
  margin: 0;
}
.footer-developer-text-mobile a {
  color: #828282;
  text-decoration: none;
  border-bottom: 1px solid #828282;
}
