.header {
  position: absolute;
  width: 100%;
  top: 0;
  background: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  z-index: 1001;
}

.header-not-main {
  position: fixed;
  width: 100%;
  top: 0;
  background: #f6f4f4;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  z-index: 1001;
  padding-bottom: 2vw;
}

.x-mark {
  cursor: pointer;
  position: relative;
  width: 2vw;
  height: 2vw;
  top: 0.1vw;
}

.x-mark-black {
  cursor: pointer;
  position: relative;
  width: 12vw;
  height: 12vw;
  top: 0.1vw;
}

.sticky-header {
  position: fixed;
  top: 0;
  background: #f6f4f4;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 2vw;
  box-sizing: border-box;
  z-index: 1001;
  transition: background-color 0.3s ease;
}

.main-logo {
  max-width: 10vw;
  height: auto;
  padding-left: 5vw;
  padding-top: 2vw;
}

.main-logo img {
  width: 8.5vw;
  height: auto;
}

.menu-controls {
  padding-right: 5.5vw;
  display: flex;
  align-items: center;
  margin-top: 1vw;
}

.phone-number {
  text-decoration: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background:#0b0a09;
  height: 3.5vw;
  padding: 0px 2vw;
  border-radius: 100px;
  color: #ffffff;
  font-family: 'Inter', sans-serif;
  font-size: 1.3vw;
  font-weight: 300;
  line-height: 2vw;
  letter-spacing: 0;
  text-align: left;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-right: 1vw;
  text-decoration: none;
}
.sticky-phone-number {
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3vw;
  padding: 0px 2vw;
  border-radius: 100px;
  font-family: 'Inter', sans-serif;
  font-size: 1.3vw;
  font-weight: 300;
  line-height: 2vw;
  letter-spacing: 0;
  text-align: left;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-right: 0.5vw;
  text-decoration: none;
  color: #000;
}

.phone-number:hover {
  background-color: #3c3c3c;
}

.phone-icon {
  padding-right: 1vw;
  height: 1.5vw;
  width: auto;
}

.phone-number a {
  color: #ffffff;
  text-decoration: none;
}

.sticky-phone-number a {
  color: #000000;
  text-decoration: none;
}

.phone-number a:hover {
  text-decoration: none;
}

.sticky-phone-number:hover {
  background-color: rgba(194, 194, 194, 0.4);
}

.menu-button {
  background-color: #0b0a09;
  border: none;
  cursor: pointer;
  position: relative;
  width: 4vw;
  height: 4vw;
  border-radius: 50%;
  transition: background-color 0.3s ease;
}

.x-mark {
  color: white;
  cursor: pointer;
  position: relative;
  width: 2vw;
  height: 2vw;
  top: 0.1vw;
}

.x-mark-black {
  color: black;
  cursor: pointer;
  position: relative;
  width: 2vw;
  height: 2vw;
  top: 0.1vw;
}

.sticky-menu-button {
  background-color: #0b0a09;
  border: none;
  cursor: pointer;
  position: relative;
  width: 4vw;
  height: 4vw;
  border-radius: 50%;
  transition: background-color 0.3s ease;
}

.menu-button::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 4.5vw;
  height: 4.5vw;
  border-radius: 50%;
}

.sticky-menu-button::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 4vw;
  height: 4vw;
  border-radius: 50%;
  transition: background-color 0.3s ease;
}

.menu-button:hover {
  background-color: #3c3c3c;
}

.sticky-menu-button:hover {
  content: '';
  background-color: rgba(194, 194, 194, 0.4);
}

.menu-icon {
  text-decoration: none;
  border: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 2vw;
  height: 2vw;
}

.menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background-color: #f6f4f4;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: auto;
}

.menu ul {
  list-style-type: none;
  text-align: center;
  color: #000;
}

.menu ul li {
  padding: 1.5vw;
  position: relative;
  cursor: pointer;
}

.menu ul li a {
  font-family: 'Inter', sans-serif;
  font-size: 1.6vw;
  font-weight: 400;
  line-height: 1.6vw;
  letter-spacing: 0em;
  text-decoration: none;
  color: #000;
  position: relative;
}

.menu ul li a:hover {
  padding-bottom: 0.5vw;
  border-bottom: 2px solid #000;
}

.additional-content {
  position: absolute;
  bottom: 2vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.additional-content a {
  font-family: 'Inter', sans-serif;
  font-size: 1vw;
  font-weight: 300;
  line-height: 1.6vw;
  text-align: left;
  margin: 0.3vw 2vw;
  letter-spacing: 0em;
  text-decoration: none;
  color: #828282;
  transition: color 0.3s ease;
}
.additional-content a:hover {
  color: #3c3c3c;
}
