.guarantees-section {
  padding-top: 12vw;
  padding-left: 6vw;
  padding-bottom: 7vw;
  background-color: #f6f4f4;
}
.guarantees-wrapper {
  display: flex;
  flex-direction: column;
  gap: 3.3vw;
}
.guarantees-head {
  font-family: 'Ubuntu', sans-serif;
  font-size: 3vw;
  font-weight: 400;
  line-height: 4vw;
  text-align: left;
  margin: 0;
}

.guarantees-text-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 48vw;
  height: 50px;
}

.guarantees-digit {
  font-family: 'Ubuntu', sans-serif;
  font-size: 2vw;
  font-weight: 300;
  padding: 1vw;
  line-height: 2.4vw;
  letter-spacing: 0em;
  text-align: left;
}

.guarantees-text {
  font-family: 'Inter', sans-serif;
  font-size: 1.4vw;
  font-weight: 300;
  line-height: 1.7vw;
  letter-spacing: 0em;
  text-align: left;
  color: #0b0a09;
}
