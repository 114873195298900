.drop-down-menu::after {
  content: '';
  display: block;
  height: 1px;
  width: 91vw;
  background-color: black;
  margin: 2vw 0;
  box-sizing: border-box;
}

.drop-down-menu {
  position: relative;
  display: grid;
  grid-template-columns: 60% 35% 5%;
  align-items: center;
  cursor: pointer;
  user-select: none;
}

.drop-down-menu p {
  font-family: 'Ubuntu', sans-serif;
  font-size: 1.8vw;
  font-weight: 400;
  line-height: 2vw;
  letter-spacing: 0em;
  text-align: left;
  margin: 0;
}

.drop-down-menu-title-text {
  transition: transform 0.5s ease;
}
.drop-down-menu:hover .drop-down-menu-title-text {
  transform: translateX(5vw);
}
.drop-down-menu .animated-arrow {
  width: 4vw;
  justify-self: end;
  transition: transform 0.3s ease;
}

.drop-down-menu.open .animated-arrow {
  transform: rotate(-90deg);
}

.dropdown-content {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.6s ease;
  display: grid;
  grid-template-columns: 1fr 1fr;
  position: relative;
}

.dropdown-content ul {
  list-style-type: none;
  position: relative;
  padding-left: 1vw;
  padding-bottom: 3vw;
}

.dropdown-content li {
  position: relative;
}

.dropdown-content ul li {
  font-family: 'Inter', sans-serif;
  font-size: 1.5vw;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
  width: 30vw;
  padding: 1vw;
  white-space: pre-line;
}

.dropdown-content li::before {
  content: '';
  position: absolute;
  top: 50%;
  left: -1vw;
  width: 1.3vw;
  height: 1.3vw;
  background-color: #c4c4c4;
  border-radius: 50%;
  transform: translate(0, -50%);
}

.lines:before {
  content: '';
  position: absolute;
  top: 4vw;
  left: 0.6vw;
  height: 82%;
  width: 1px;
  background-color: #c4c4c4;
}

.dropdown-content img {
  height: 40vw;
  justify-self: end;
  width: auto;
}

.drop-down-menu.open + .dropdown-content {
  max-height: 100vw;
}

.drop-down-menu-button-container {
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  bottom: -80%;
  right: -171%;
  text-decoration: none;
}

.drop-down-menu-button-container img {
  width: 4vw;
  height: 4vw;
}

.drop-down-menu-button-text {
  padding: 1vw;
  font-family: 'Ubuntu', sans-serif;
  font-size: 2vw;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
  color: black;
}
